<template>
  <v-container v-if="has_view_permission" class="px-0">
    <v-data-table
        :headers="column_headers"
        :items="table_items"
        sort-by="id"
        class="elevation-5 yellow lighten-2"
        :loading="table_loading"
        loading-text="Loading... Please wait"
        item-key="id"
        name="lawyer_table"
    >
      <template v-slot:top>
        <v-card-title
            flat
        >
          Lawyers
          <v-spacer></v-spacer>
          <add_edit_form v-if="add_button" activator_name="Add New lawyer"
                         :form_title="`Add New Lawyer`"
                         :url="add_url"
                         activator_icon="mdi-plus-thick" action="add" @form_status="get_lists"
                         big_btn="true"
                         snackbar_text="Lawyer#{value} has been created successfully"
                         :loading="reload_btn"
          ></add_edit_form>
          <v-btn :loading="reload_btn" @click="get_lists" class="ma-1">
            <v-icon>
              mdi-reload
            </v-icon>
            Reload Data Table
          </v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.actions="{ item }">
        <add_edit_form v-if="edit_button" activator_name="Edit"
                       form_title="Edit Lawyer Information" :passed_item="item"
                       :url="edit_url.replace('{id}', item.id)"
                       activator_icon="mdi-pencil" action="edit" @form_status="get_lists"
                       tooltip_text="Edit"
                       :snackbar_text="`Lawyer#${item.name}'s information has been updated successfully`">

        </add_edit_form>
        <delete_dialog v-if="delete_button" @form_status="get_lists" :passed_item="item"
                       :dialogue_text="`Are you sure you want to Delete Lawyer# ${item.name}?`"
                       :url="`${delete_url.replace('{id}', item.id)}`"
                       activator_name="Delete" activator_icon="mdi-delete"
                       tooltip_text="Delete"
                       snackbar_text="Lawyer#{value} is deleted"
                       action="delete"
        ></delete_dialog>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>


import axios from "axios";
import Personnel_urls from "@/constants/personnel_urls";
import add_edit_form from "@/components/personnel_components/lawyer_sub_component/add_edit_form";
import delete_dialog from "@/components/personnel_components/lawyer_sub_component/delete_dialog";

export default {
  name: "LawyerList",
  components: {add_edit_form, delete_dialog},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
    has_view_permission() {
      return this.$store.getters.permissionCheckers('personnel.view_lawyers')
    },
    add_button() {
      return this.$store.getters.permissionCheckers('personnel.add_lawyers')
    },
    edit_button() {
      return this.$store.getters.permissionCheckers('personnel.change_lawyers')
    },
    delete_button() {
      return this.$store.getters.permissionCheckers('personnel.delete_lawyers')
    },
  },
  data: () => ({
    table_items: [],
    skeleton_loader_attrs: {
      boilerplate: true,
    },
    show_snackbar: false,
    snackbar_text: '',
    list_url: Personnel_urls.lawyer.list,
    add_url: Personnel_urls.lawyer.add,
    edit_url: Personnel_urls.lawyer.edit,
    delete_url: Personnel_urls.lawyer.delete,
    table_loading: false,
    reload_btn: false,
    column_headers: [
      {
        text: 'Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'Mobile',
        align: 'center',
        filterable: true,
        value: 'mobile_no',
      },
      {
        text: 'Email',
        align: 'center',
        filterable: true,
        value: 'email',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: true,
        value: 'actions',
      },
    ],
  }),
  methods: {
    get_lists() {
      this.axios_conf
      let self = this
      let config = {
        headers: {
          multi: 'false'
        }
      }
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      axios.get(this.list_url, config).then((resp) => {
        this.table_items = resp.data.items
        this.$store.commit('setJWT', resp.data.key)
        self.table_loading = false
        this.reload_btn = false
      })
    },
  },
  mounted() {
    this.get_lists()
  },
  watch: {}
}
</script>

<style>
div[name="lawyer_table"] > div > table > tbody > tr:hover {
  background: #82B1FF !important;
}
</style>