const Personnel_urls = {
  lawyer: {
    list: "personnel/lawyer/",
    add: "personnel/lawyer/",
    edit: "personnel/lawyer/{id}/update",
    delete: "personnel/lawyer/{id}/delete",
  },
  personnel: {
    list: "personnel/personnelviews/",
  },
};

export default Personnel_urls;
