<template>
  <v-container>
    <lawyer-list/>
  </v-container>
</template>

<script>
import LawyerList from "@/components/personnel_components/lawyer_sub_component/LawyerList";

export default {
  name: "LawyerPage",
  components: {LawyerList}
}
</script>

<style scoped>

</style>